import React from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { util, config } from "../_helpers";
import { accountService, alertService } from "../_services";
import { gapi } from "gapi-script";
import { GoogleLogin } from "react-google-login";

function Login({ history, location }) {
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const gLoginSuccess = (gData) => {
    console.log("google logged in success", gData);
    if (gData.profileObj) {
      onSubmit(
        { email: gData.profileObj.email, password: gData.tokenId },
        { setSubmitting: () => true }
      );
    }
  };

  const gLoginFailed = (err) => {
    console.log("google log in failed", err);
  };

  const initGoogleClient = () => {
    gapi.client.init({
      clientId: config.Google_Client_Id,
      scope: "",
    });
  };
  gapi.load("client:auth2", initGoogleClient);

  const redirectPostSuccess = (user) => {

    const { from } = location.state || { from: { pathname: "/" } };
    let redirectUrl = util.getParameterByName("redirect");
    redirectUrl
      ? (window.location.href = redirectUrl + "?token=" + user.token)
      : user.role.toUpperCase() == "SUPER-ADMIN"
      ? history.push("/admin/users")
      : history.push(`/profile/view/${user.id}`); 
      // : history.push(`/profile/7788778`); //Edit by rabindra
  };

  function onSubmit({ email, password }, { setSubmitting }) {
    alertService.clear();
    accountService
      .login(email, password)
      .then((res) => {
        redirectPostSuccess(res.user);
      })
      .catch((error) => {
        setSubmitting(false);
        alertService.error(error);
      });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form >
          <h5 className="card-header">Welcome, Please Login!</h5>

          {config.Google_Client_Id && <div><div style={{ textAlign: "center" }} className="form-group">
            <GoogleLogin
              clientId={config.Google_Client_Id}
              buttonText="LOGIN WITH GOOGLE"
              onSuccess={gLoginSuccess}
              onFailure={gLoginFailed}
              cookiePolicy={"single_host_origin"}
            />
          </div>
          <div style={{ width: "100%", color: "#ccc", textAlign: "center" }}>
            {"---------- OR ----------"}
          </div></div>}
          <div className="card-body">
            <div className="form-group">
              <label>Email</label>
              <Field
                name="email"
                type="text"
                className={
                  "form-control" +
                  (errors.email && touched.email ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="email"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label>Password</label>
              <Field
                name="password"
                type="password"
                className={
                  "form-control" +
                  (errors.password && touched.password ? " is-invalid" : "")
                }
              />
              <ErrorMessage
                name="password"
                component="div"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <button
                style={{ width: "100%" }}
                type="submit"
                disabled={isSubmitting}
                className="btn btn-primary"
              >
                {isSubmitting && (
                  <span className="spinner-border spinner-border-sm mr-1"></span>
                )}
                Login
              </button>
            </div>
            <div className="form-group">
              <Link
                to="forgot-password"
                className="btn btn-link pr-0"
                style={{ paddingLeft: "0px" }}
              >
                Forgot Password?
              </Link>
              {config.Google_Client_Id && <Link
                to={"register?redirect=" + util.getParameterByName("redirect")}
                className="btn btn-link"
              >
                Sign Up
              </Link>}
<div style={{fontSize:"0.8em",textAlign:"center",marginTop:"10px"}}>&#169; DigitalLive24 2025</div>
            </div>

          </div>

        </Form>

      )}
    </Formik>
  );
}

export { Login };
